import { useDraftStore } from '~/stores/draft'
import { useMiscDraftProgressivePromosStore } from '~/stores/miscDraftProgressivePromos'

export default defineNuxtRouteMiddleware(async () => {
  const { $pinia } = useNuxtApp()

  const miscDraftProgressivePromosStore =
    useMiscDraftProgressivePromosStore($pinia)
  const draftStore = useDraftStore($pinia)

  await miscDraftProgressivePromosStore.FETCH()
  await draftStore.CHECK_FOR_PROGRESSIVE_PROMO()
})
